import { ROUTES } from 'app/config/routes'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { getUser } from 'app/services/user'
import {
  selectAccessToken,
  selectIsAuthenticated,
  setAccessToken,
  setCurrentUser,
  setIsAuthenticated,
} from 'features/auth/authSlice'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export function PrivateRoute(props: any) {
  const navigate = useNavigate()
  const { children } = props
  const idToken = useAppSelector(selectAccessToken)
  const isAuthenticated = useAppSelector(selectIsAuthenticated)
  const dispatch = useAppDispatch()

  async function init() {
    if (!idToken) {
      return navigate(ROUTES.AUTH.LOGIN)
    }
    try {
      const user = await getUser()
      dispatch(setCurrentUser(user))
      dispatch(setIsAuthenticated(true))
    } catch (error: any) {
      dispatch(setCurrentUser(null))
      dispatch(setAccessToken(null))
      dispatch(setIsAuthenticated(false))
      return navigate(ROUTES.AUTH.LOGIN)
    }
  }

  useEffect(() => {
    init()
  }, [])

  return !isAuthenticated ? (
    <section className="flex h-screen justify-center items-center">
      Loading
    </section>
  ) : (
    children
  )
}
