import { API_HOST, AUTHENTICATE_TOKEN_KEY } from 'app/config'
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { jwtDecode } from 'jwt-decode'
import { authHTApi } from '../auth'

const notRefreshToken = ['/api/v1/me', '/api/v1/tasks']
const apiClient = axios.create({
  baseURL: API_HOST,
  headers: {},
})
export interface AuthToken {
  token_type: string
  idToken: string
  refreshToken: string
}

export const setHeader = (authToken: AuthToken) => {
  if (!authToken) {
    return
  }
  apiClient.defaults.headers.common.Authorization = `${authToken.token_type} ${authToken.idToken}`
}

function onResponse(response: AxiosResponse): AxiosResponse {
  return response.data
}

const refreshTokenIfNeed = async (
  config: AxiosRequestConfig,
): Promise<AuthToken | null> => {
  const accessToken = JSON.parse(
    window.localStorage.getItem(AUTHENTICATE_TOKEN_KEY) || 'null',
  ) as AuthToken
  if (!accessToken) {
    return null
  }
  if (notRefreshToken.includes(config.url || '')) {
    return accessToken
  }
  const authToken = jwtDecode(accessToken.idToken)
  if ((authToken.exp || 0) * 1000 - Date.now() > 5 * 60 * 1000) {
    return accessToken
  }

  const payload = {
    userPoolName: 'clinic-admin',
    userName: authToken.sub,
    refreshToken: accessToken.refreshToken,
  }
  try {
    const result = await authHTApi.getUserAccessToken(payload)
    const { idToken: newIdToken } = result.data
    const newAuthToken: AuthToken = {
      token_type: 'Bearer',
      idToken: newIdToken,
      refreshToken: accessToken.refreshToken,
    }
    setHeader(newAuthToken)
    window.localStorage.setItem(
      AUTHENTICATE_TOKEN_KEY,
      JSON.stringify(newAuthToken),
    )
    return newAuthToken
  } catch (error: any) {
    // eslint-disable-next-line no-console
    console.error('error refresh token', error)
  }
  return null
}

async function onRequest(config: AxiosRequestConfig) {
  const accessToken = await refreshTokenIfNeed(config)
  if (accessToken) {
    const authorizationToken = `Bearer ${accessToken?.idToken}`
    config.headers = { ...config.headers, Authorization: authorizationToken }
  }
  return config
}

apiClient.interceptors.request.use(onRequest)
apiClient.interceptors.response.use(onResponse)

if (window.localStorage.getItem(AUTHENTICATE_TOKEN_KEY)) {
  const authToken = JSON.parse(
    window.localStorage.getItem(AUTHENTICATE_TOKEN_KEY) || 'null',
  ) as AuthToken
  setHeader(authToken)
}

export { apiClient }
